import {
  useModalEntityListContext,
  Response,
} from "@/contexts/ModalEntityListContext";
import { FC, MouseEventHandler, PropsWithChildren, useCallback } from "react";

interface Props {
  responses: Response[];
}

const ModalEntityListWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  responses,
}) => {
  const { setResponses } = useModalEntityListContext();
  const clickHandler = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation();

      setResponses(responses);
    },
    [responses, setResponses],
  );

  return <div onClick={clickHandler}>{children}</div>;
};

export default ModalEntityListWrapper;
