import { FC, PropsWithChildren } from "react";
import { LikeKind, ListItemKind, ProjectResponse } from "@/web-client/api";
import { ImageSize } from "@/consts/ImageSize";
import Link from "next/link";
import AddListBtnWrapper from "@/features/list/components/AddListBtnWrapper";
import LinkAsModal, { ModalEntityType } from "@/components/LinkAsModal";
import TeamIcon from "@/features/team/components/TeamIcon";
import LikeIconBtn from "@/features/like/components/LikeIconBtn";
import useLike, { LikeMutateHandler } from "@/features/like/hooks/useLike";
import FadingBackgroundImage from "@/components/FadingBackgroundImage";
import IconBookmark from "@/assets/imgs/svg/icon-bookmark.svg";
import useIsMyTeam from "@/features/team/hooks/useIsMyTeam";
import ProjectItemMenuBtn from "@/features/project/components/ProjectItemMenuBtn";
import { AvatarSize } from "@/components/Avatar";
import LabelBadge from "@/components/LabelBadge";
import { useIsNewProject } from "@/features/project/hooks/useIsNewProject";
import { theme } from "tailwind.config";
import clsx from "clsx";
import IconView from "@/assets/imgs/svg/icon_remove_red_eye.svg";

export type ProjectItemProps = {
  project: ProjectResponse;
  hasTeam?: boolean;
  mutate?: LikeMutateHandler<ProjectResponse>;
  showMenuBtn?: boolean;
  removeMutation?: () => void;
  showActions?: boolean;
};

const ProjectItem: FC<ProjectItemProps> = ({
  project,
  mutate,
  showMenuBtn,
  removeMutation,
  hasTeam = true,
  showActions = true,
}) => {
  const { likeHandler } = useLike<ProjectResponse>(
    project,
    LikeKind.PROJECT,
    mutate,
  );
  const isMyTeam = useIsMyTeam(project.team.id);
  const isNewProject = useIsNewProject(project.published_at);

  return (
    <article className={clsx("relative", "group/project-item")}>
      <div className="grid gap-y-8 leading-tight">
        <div className="rounded-xs overflow-hidden relative">
          <div
            className={clsx(
              "relative aspect-video bg-gray-50",
              "group-hover/project-item:brightness-90 transition-all",
            )}
          >
            <LinkForProject project={project}>
              <a>
                {project.main_image && (
                  <FadingBackgroundImage
                    image={project.main_image}
                    imageSize={ImageSize.Small}
                    aspectRatio="16/9"
                  />
                )}
              </a>
            </LinkForProject>
          </div>

          {!project.is_published && (
            <div className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center pointer-events-none">
              <p className="font-bold text-white text-sm">
                非公開のプロジェクト
              </p>
            </div>
          )}

          <dl className="pointer-events-none">
            <dt className="sr-only">ビルディングタイプ</dt>
            <dd className="absolute bottom-1 left-1 p-6 bg-black bg-opacity-70 text-white rounded-xxs text-xs opacity-0 group-hover/project-item:opacity-100 transition-all">
              {project.building_type.name}
            </dd>
          </dl>

          {isMyTeam && showMenuBtn && (
            <div className="absolute top-[8px] right-[8px]">
              <ProjectItemMenuBtn
                projectId={project.id}
                onDeleted={removeMutation}
                isPublished={project.is_published}
              />
            </div>
          )}
        </div>

        <div className="grid gap-y-6">
          <h2 className="text-sm font-bold truncate">
            <LinkForProject project={project}>
              <a>{project.title}</a>
            </LinkForProject>
          </h2>

          {hasTeam && (
            <TeamIcon
              team={project.team}
              size={AvatarSize.Tiny}
              textSize="text-xs"
            />
          )}

          {showActions && (
            <div className="flex justify-between items-center order-last">
              <div className="flex items-center gap-8">
                {/* いいね数 */}
                <div className="flex items-center gap-4">
                  <LikeIconBtn
                    hasLiked={project.has_liked}
                    onClick={likeHandler}
                    size={16}
                  />
                  <p className="text-xs text-gray-600 leading-none">
                    {project.likes_count}
                  </p>
                </div>

                {/* ビュー数 */}
                <div className="flex items-center gap-4 fill-gray-300">
                  <IconView width={16} height={16} />
                  <p className="text-xs text-gray-600 leading-none">
                    {project.page_views_count}
                  </p>
                </div>
              </div>
              <AddListBtnWrapper
                kind={ListItemKind.PROJECT}
                selectedItem={project}
              >
                <span className="block rounded-full">
                  <IconBookmark
                    width={16}
                    height={16}
                    fill={theme.colors.gray[300]}
                  />
                </span>
              </AddListBtnWrapper>
            </div>
          )}
        </div>
      </div>

      {isNewProject && (
        <div className="absolute top-[4px] left-[4px] pointer-events-none">
          <LabelBadge kind="new" />
        </div>
      )}
    </article>
  );
};

export default ProjectItem;

const LinkForProject: FC<PropsWithChildren<{ project: ProjectResponse }>> = ({
  children,
  project,
}) => {
  if (!project.is_published) {
    return (
      <Link href={`/projects/${project.id}`} prefetch={false} legacyBehavior>
        {children}
      </Link>
    );
  }

  return (
    <LinkAsModal
      entityId={project.id}
      entityType={ModalEntityType.Project}
      as={`/projects/${project.id}`}
    >
      {children}
    </LinkAsModal>
  );
};
